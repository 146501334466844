<template>
    <b-card title="Design Tag">
        <b-form>
            <b-row>
                <b-col md="2">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" placeholder="Select Store Name"
                            :options="storeNameOptions" />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Width in mm">
                        <!-- <vSelect :options="widthOptions" v-model="width" placeholder="---Select---"></vSelect> -->
                        <b-form-spinbutton v-model="width" min="1" max="500" />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Height in mm">
                        <!-- <vSelect :options="heightOptions" v-model="height" placeholder="---Select---"></vSelect> -->
                        <b-form-spinbutton v-model="height" min="1" max="500" />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Printer">
                        <vSelect :options="printerTypeOptions" v-model="printerType" label="name"></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Align">
                        <vSelect :options="barcodeAlignOptions" v-model="barcodeAlign"></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-checkbox checked="false" v-model="setWetStrengthPaper" name="check-button"
                        class="mt-2 mr-2">
                        Set This For Wet Strength Paper
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="8">
                    <b-row>
                        <b-col md="4">
                            <tr>
                                <td>
                                    QR Code
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="qrcode" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Order No
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printBookingNo" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Customer Name
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printCustomerName" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Address
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printAddress" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Process
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printProcess" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>

                        </b-col>

                        <b-col md="4">
                            <!-- <tr>
                                <td>
                                    Item Total
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printItemTotal" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr> -->
                            <tr>
                                <td>
                                    Remark
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printRemark" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Color
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printColour" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Order Date
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printBookingDate" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Due Date
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printDueDate" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                        </b-col>

                        <b-col md="4">
                            <!-- <tr>
                                <td>
                                    Order Time
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printOrderTime" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr> -->
                            <tr>
                                <td>
                                    Item Name
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printItemName" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Store Name
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printStoreName" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Logo
                                </td>
                                <td>
                                    <b-form-checkbox @input="setQrCodeDesign" v-model="printLogo" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Auto Cutter
                                </td>
                                <td>
                                    <b-form-checkbox v-model="enabelAutoCutter" switch>
                                    </b-form-checkbox>
                                </td>
                            </tr>
                        </b-col>


                    </b-row>
                    <b-row>
                        <b-col class="text-center mt-2">
                            <b-button variant="primary" @click="addUpdateBarcode">{{ addUpdateButton }}</b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="4" class="text-center">
                    <!-- <b-img v-bind="mainProps" blank-color="#475F7B" rounded alt="Design Tag QR"
                        class="d-inline-block mr-1 mb-1" /> -->
                    <QRCodeDesign ref="qrCodeDesign"></QRCodeDesign>
                </b-col>
            </b-row>

        </b-form>
        <hr>
        <div>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: Width Height -->
                    <span v-if="props.column.field === 'barCOdeHeight'">
                        <span>
                            {{ props.row.barCodeWidth }}mm <b>x</b> {{ props.row.barCOdeHeight }}mm
                        </span>

                    </span>

                    <!-- Column: Printer Type -->
                    <span v-else-if="props.column.field === 'barcodePrintPrinterType'">
                        <span>
                            {{ printerTypeForTable(props.row.barcodePrintPrinterType) }}
                        </span>

                    </span>

                    <span v-else-if="props.column.field === 'setWetStrengthPaper'" class="text-nowrap">
                        <span v-if="props.row.setWetStrengthPaper === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.setWetStrengthPaper === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'qrcode'" class="text-nowrap">
                        <span v-if="props.row.qrcode === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.qrcode === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printBookingNo'" class="text-nowrap">
                        <span v-if="props.row.printBookingNo === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printBookingNo === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printCustomerName'" class="text-nowrap">
                        <span v-if="props.row.printCustomerName === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printCustomerName === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printAddress'" class="text-nowrap">
                        <span v-if="props.row.printAddress === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printAddress === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printProcess'" class="text-nowrap">
                        <span v-if="props.row.printProcess === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printProcess === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <!-- <span v-else-if="props.column.field === 'printItemTotal'" class="text-nowrap">
                        <span v-if="props.row.printItemTotal === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printItemTotal === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span> -->

                    <span v-else-if="props.column.field === 'printRemark'" class="text-nowrap">
                        <span v-if="props.row.printRemark === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printRemark === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printColour'" class="text-nowrap">
                        <span v-if="props.row.printColour === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printColour === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printBookingDate'" class="text-nowrap">
                        <span v-if="props.row.printBookingDate === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printBookingDate === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>


                    <span v-else-if="props.column.field === 'printDueDate'" class="text-nowrap">
                        <span v-if="props.row.printDueDate === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printDueDate === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <!-- <span v-else-if="props.column.field === 'printOrderTime'" class="text-nowrap">
                        <span v-if="props.row.printOrderTime === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printOrderTime === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span> -->

                    <span v-else-if="props.column.field === 'printItemName'" class="text-nowrap">
                        <span v-if="props.row.printItemName === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printItemName === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printStoreName'" class="text-nowrap">
                        <span v-if="props.row.printStoreName === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printStoreName === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'printLogo'" class="text-nowrap">
                        <span v-if="props.row.printLogo === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.printLogo === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'enabelAutoCutter'" class="text-nowrap">
                        <span v-if="props.row.enabelAutoCutter === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.enabelAutoCutter === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                            <b-button @click="getStoreBarcodeByStoreId(props.row.store.id)"
                                variant="flat-primary"><feather-icon icon="EditIcon"
                                    class="text-body align-middle mr-25" /></b-button>
                            <b-button @click="deleteDesignTag(props.row.id)" variant="flat-primary"><feather-icon
                                    icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>

                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BImg, BButton, BFormCheckbox, BFormSpinbutton, BPagination, BFormSelect, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import util from '@/store/utils'
import { VueGoodTable } from 'vue-good-table'

import QRCodeDesign from './QRCodeDesign.vue'


export default {
    components: {
        BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BImg, BButton,
        BFormCheckbox, BFormSpinbutton, vSelect, VueGoodTable,
        BPagination, BFormSelect, BDropdown, BDropdownItem, QRCodeDesign
    },
    data: () => ({
        addUpdateButton: 'Add',
        printerTypeOptions: [],
        printerType: { 'id': 0, 'name': '---Select---' },
        barcodeAlignOptions: util.barcodeAlignOptions(),
        barcodeAlign: util.barcodeAlign(),
        mainProps: {
            blank: true,
            width: 200,
            height: 200,
            class: 'm1',
        },
        qrcode: false,
        printBookingNo: false,
        printCustomerName: false,
        printAddress: false,
        printProcess: false,
        printItemTotal: false,
        printRemark: false,
        printColour: false,
        printBookingDate: false,
        printDueDate: false,
        printOrderTime: false,
        printItemName: false,
        printStoreName: false,
        printLogo: false,
        enabelAutoCutter: false,
        storeNameOptions: [],

        storeName: '',
        widthOptions: util.widthOptions,
        width: 0,
        heightOptions: util.heightOptions,
        height: 0,
        setWetStrengthPaper: false,
        searchTerm: '',
        rows: [],
        id: 0,
        pageLength: 10,
        dir: false,
        columns: [
            {
                label: 'Store Name',
                field: 'store.storeName',
            },
            {
                label: 'Width X Height',
                field: 'barCOdeHeight',
            },
            {
                label: 'Printer',
                field: 'barcodePrintPrinterType',
            },
            {
                label: 'Align',
                field: 'barCodeAlign',
            },
            {
                label: 'Set For Wet Paper',
                field: 'setWetStrengthPaper',
            },
            {
                label: 'QR Code',
                field: 'qrcode',
            },
            {
                label: 'Order No',
                field: 'printBookingNo',
            },
            {
                label: 'Customer Name',
                field: 'printCustomerName',
            },
            {
                label: 'Address',
                field: 'printAddress',
            },
            {
                label: 'Process',
                field: 'printProcess',
            },
            // {
            //     label: 'Item Total',
            //     field: 'printItemTotal',
            // },
            {
                label: 'Remark',
                field: 'printRemark',
            },
            {
                label: 'Color',
                field: 'printColour',
            },
            {
                label: 'Order Date',
                field: 'printBookingDate',
            },
            {
                label: 'Due Date',
                field: 'printDueDate',
            },
            // {
            //     label: 'Order Time',
            //     field: 'printOrderTime',
            // },
            {
                label: 'Item Name',
                field: 'printItemName',
            },
            {
                label: 'Store Name',
                field: 'printStoreName',
            },
            {
                label: 'Logo',
                field: 'printLogo',
            },
            {
                label: 'Auto Cutter',
                field: 'enabelAutoCutter',
            },
            {
                label: 'Action',
                field: 'action',
            },
        ],

    }),
    methods: {
        getPrinterType() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getPrintersType', data))
                .then(function (response) {
                    let i = 0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.printerTypeOptions.push({ 'id': i, 'name': response.data[index] });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        printerTypeForTable(printerTypeId) {
            if (printerTypeId > 0) {
                const object = this.printerTypeOptions.find(obj => obj.id == printerTypeId);
                return object.name;
            } else {
                return '';
            }
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
            self.storeName = { 'id': self.$store.state.storeId, 'storeName': self.$store.state.storeName };
        },

        getStoreBarcode() {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/barcodeConfiguration', data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    self.rows = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getStoreBarcodeByStoreId(storeId) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/barcodeConfiguration/' + storeId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.addUpdateButton = 'Update';

                    self.storeName = { 'id': response.data.store.id, 'storeName': response.data.store.storeName };

                    const objectWidth = self.widthOptions.find(obj => obj == response.data.barCodeWidth);
                    self.width = objectWidth;

                    const objectHeight = self.heightOptions.find(obj => obj == response.data.barCOdeHeight);
                    self.height = objectHeight;

                    const objectPrinter = self.printerTypeOptions.find(obj => obj.id == response.data.barcodePrintPrinterType);
                    self.printerType = { 'id': objectPrinter.id, 'name': objectPrinter.name };

                    const objectAlign = self.barcodeAlignOptions.find(obj => obj == response.data.barCodeAlign);
                    self.barCodeAlign = objectAlign;

                    self.setWetStrengthPaper = response.data.setWetStrengthPaper;

                    self.qrcode = response.data.qrcode;
                    self.printBookingNo = response.data.printBookingNo;
                    self.printCustomerName = response.data.printCustomerName;
                    self.printAddress = response.data.printAddress;
                    self.printProcess = response.data.printProcess;
                    self.printItemTotal = response.data.printItemTotal;
                    self.printRemark = response.data.printRemark;
                    self.printColour = response.data.printColour;
                    self.printBookingDate = response.data.printBookingDate;
                    self.printDueDate = response.data.printDueDate;
                    self.printOrderTime = response.data.printOrderTime;
                    self.printItemName = response.data.printItemName;
                    self.printStoreName = response.data.printStoreName;
                    self.printLogo = response.data.printLogo;
                    self.enabelAutoCutter = response.data.enabelAutoCutter;

                    self.setQrCodeDesign();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addUpdateBarcode() {
            let self = this;
            if (!(self.storeName.storeName == '')) {
                if (self.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "store": { 'id': self.storeName.id, 'storeName': self.storeName.storeName },
                        "barCOdeHeight": self.height,
                        "barCodeWidth": self.width,
                        "barcodePrintPrinterType": self.printerType.id,
                        "setWetStrengthPaper": self.setWetStrengthPaper,
                        "barCodeAlign": self.barcodeAlign,
                        "qrcode": self.qrcode,
                        "printBookingNo": self.printBookingNo,
                        "printCustomerName": self.printCustomerName,
                        "printAddress": self.printAddress,
                        "printProcess": self.printProcess,
                        "printItemTotal": self.printItemTotal,
                        "printRemark": self.printRemark,
                        "printColour": self.printColour,
                        "printBookingDate": self.printBookingDate,
                        "printDueDate": self.printDueDate,
                        "printOrderTime": self.printOrderTime,
                        "printItemName": self.printItemName,
                        "printStoreName": self.printStoreName,
                        "printLogo": self.printLogo,
                        "enabelAutoCutter": self.enabelAutoCutter
                    });
                    axios(api.getApi('Post', '/barcodeConfiguration', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Design Tag Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getStoreBarcode();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "store": { 'id': self.storeName.id, 'storeName': self.storeName.storeName },
                        "barCOdeHeight": self.height,
                        "barCodeWidth": self.width,
                        "barcodePrintPrinterType": self.printerType.id,
                        "setWetStrengthPaper": self.setWetStrengthPaper,
                        "barCodeAlign": self.barCodeAlign,
                        "qrcode": self.qrcode,
                        "printBookingNo": self.printBookingNo,
                        "printCustomerName": self.printCustomerName,
                        "printAddress": self.printAddress,
                        "printProcess": self.printProcess,
                        "printItemTotal": self.printItemTotal,
                        "printRemark": self.printRemark,
                        "printColour": self.printColour,
                        "printBookingDate": self.printBookingDate,
                        "printDueDate": self.printDueDate,
                        "printOrderTime": self.printOrderTime,
                        "printItemName": self.printItemName,
                        "printStoreName": self.printStoreName,
                        "printLogo": self.printLogo,
                        "enabelAutoCutter": self.enabelAutoCutter
                    });
                    axios(api.getApi('put', '/barcodeConfiguration/' + self.storeName.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getStoreBarcode();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Design Tag Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Select Design Tag.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        deleteDesignTag(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/barcodeConfiguration/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getStoreBarcode();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        setQrCodeDesign() {
            this.$refs.qrCodeDesign.setQrCodeTagDesign(this.width, this.barCodeAlign, this.printLogo, this.printBookingNo, this.qrcode, this.printCustomerName, this.printAddress, this.printProcess, this.printItemTotal, this.printRemark, this.printColour, this.printBookingDate, this.printOrderTime, this.printDueDate, this.printItemName, this.printStoreName);
        }

    },
    created() {
        let self = this;
        self.getStoreName();
        self.getPrinterType();
        self.getStoreBarcode();
    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>