<template>
    <!-- <b-card> -->
    <b-row>
        <b-col class="border" :width=width>
            <b-col md="12" v-if="logo">
                <img width="50px" :src="require('@/assets/images/logo/Apollo.png')" />
            </b-col>
            <b-col md="12" v-if="storeName">
                <p>Store Name</p>
            </b-col>
            <b-col md="12" v-if="orderNo">
                <h3>#123456</h3>
            </b-col>
            <b-col md=12 v-if="qrCode" class="tag-margin-bottom">
                <!-- <b-img v-bind="mainProps" blank-color="#475F7B" rounded alt="Design Tag QR" /> -->
                <center>
                    <qrCodeTag size="100" color="#000" bg-color="#fff" error-level="H"
                        text="https://kanwatinfotech.com"></qrCodeTag>
                </center>
            </b-col>
            <b-row>
                <b-col md="12" v-if="customerName">
                    <p class="tag-margin-top tag-margin-bottom">Customer Name</p>
                </b-col>
                <b-col md="12" v-if="address">
                    <p class="tag-margin-top tag-margin-bottom">Address</p>
                </b-col>
                <b-col md="12" v-if="process">
                    <p class="tag-margin-top tag-margin-bottom">Process</p>
                </b-col>
                <b-col md="12" v-if="itemTotal">
                    <p class="tag-margin-top tag-margin-bottom">Item Total</p>
                </b-col>
                <b-col md="12" v-if="remark">
                    <p class="tag-margin-top tag-margin-bottom">Remark</p>
                </b-col>
                <b-col md="12" v-if="color">
                    <p class="tag-margin-top tag-margin-bottom">Color</p>
                </b-col>
                <b-col md="12" v-if="orderDate">
                    <p class="tag-margin-top tag-margin-bottom">Order Date</p>
                </b-col>
                <b-col md="12" v-if="orderTime">
                    <p class="tag-margin-top tag-margin-bottom">Order Time</p>
                </b-col>
                <b-col md="12" v-if="dueDate">
                    <p class="tag-margin-top tag-margin-bottom">Due Date</p>
                </b-col>
                <b-col md="12" v-if="itemName">
                    <p class="tag-margin-top tag-margin-bottom">Item Name</p>
                </b-col>

            </b-row>
        </b-col>
    </b-row>
    <!-- </b-card> -->
</template>
<script>
import {
    BCard, BRow, BCol, BImg
} from 'bootstrap-vue'
import qrCodeTag from 'vue-qrcode-component'
// import QrcodeVue from 'qrcode.vue'
export default {
    components: {
        BCard, BRow, BCol, BImg, qrCodeTag
    },
    data() {
        return {
            mainProps: {
                blank: true,
                width: 100,
                height: 100,
                class: 'm1',
            },
            value: 'https://example.com',
            size: 300,

            width: '0px',

            logo: false,
            orderNo: false,
            qrCode: false,
            customerName: false,
            address: false,
            process: false,
            itemTotal: false,
            remark: false,
            color: false,
            orderDate: false,
            orderTime: false,
            dueDate: false,
            itemName: false,
            storeName: false
        }
    },
    props: {
        printLogo: Boolean
    },
    methods: {
        setQrCodeTagDesign(width, barCodeAlign, printLogo, printOrderNo, printQrCode, printCustomerName, printAddress, printProcess, printItemTotal, printRemark, printColor, printOrderDate, printOrderTime, printDueDate, printItemName, printStoreName) {
            this.width = width + 'mm';
            this.barCodeAlign = barCodeAlign;
            this.logo = printLogo;
            this.orderNo = printOrderNo;
            this.qrCode = printQrCode;
            this.customerName = printCustomerName;
            this.address = printAddress;
            this.process = printProcess;
            this.itemTotal = printItemTotal;
            this.remark = printRemark;
            this.color = printColor;
            this.orderDate = printOrderDate;
            this.orderTime = printOrderTime;
            this.dueDate = printDueDate;
            this.itemName = printItemName;
            this.storeName = printStoreName;
        }
    }
}
</script>
<style>
.tag-margin-top {
    margin-top: -20px;
}

.tag-margin-bottom {
    margin-bottom: 20px;
}
</style>